<template>
  <div
    id="prinrLabel"
    page="A4">
    <div
      v-for="(order, orderIndex) in orders"
      :key="`order-${orderIndex}-${order.billDetail.id}`"
      class="page">
      <div class="printPlate--tag-box-large printPlate--custom__heigth1">
        <div class="printPlate--tag-box-large_inner-group-sender">
          <div class="printPlate--sender-box_large">
            <div class="printPlate--sender-head">
              {{ getLabel('sender', order.billDetail.channel) }}
            </div>
            <div class="printPlate--sender-body">
              <div class="printPlate--sender-body_address">
                {{ order.billDetail.storeAddress.address }}
              </div>
              <div class="printPlate--sender-body_post">
                <div class="printPlate--sender-body_post_left">
                  {{ getLabel('zipcode', order.billDetail.channel) }}
                </div>
                <div class="printPlate--sender-body_post_right">
                  {{ order.billDetail.storeAddress.postcode }}
                </div>
              </div>
            </div>
          </div>
          <img
            class="mx-auto"
            style="height: 120px;"
            :src="order.billDetail.qr" />
        </div>
        <div class="printPlate--tag-box-large_inner-group">
          <div class="printPlate--receiver-box_large">
            <div class="printPlate--receiver-head">
              <div class="printPlate--receiver-head_left">
                {{ getLabel('receiver', order.billDetail.channel) }}
              </div>
              <div class="printPlate--receiver-head_right">
                Tel. {{ order.customer.tel }}
              </div>
            </div>
            <div class="printPlate--receiver-body">
              <div class="printPlate--receiver-body_address">
                <div>
                  <p class="name">
                    {{ order.customer.name }}
                  </p>
                  <div class="address">
                    <p>
                      {{ order.customer.address }} {{ order.customer.address2 }}
                      <span>
                        {{ getLabel('subDistrict', order.billDetail.channel) }} {{ order.customer.subDistrict }}
                        {{ getLabel('district', order.billDetail.channel) }} {{ order.customer.district }}
                        {{ order.customer.province }}
                        {{ order.customer.country }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="printPlate--receiver-body_post">
                <div class="printPlate--receiver-body_post_left">
                  {{ getLabel('zipcode', order.billDetail.channel) }}
                </div>
                <div class="printPlate--receiver-body_post_right">
                  {{ order.customer.postcode }}
                </div>
              </div>
            </div>
          </div>
          <div class="printPlate--bar-code-box">
            <div class="printPlate--bar-code">
              <barcode
                class="printPlate--bar-code-code"
                :value="order.billDetail.id"
                :font-size="11"
                :width="1.5"
                :height="35" />
              <div class="printPlate--bar-code-detail">
                <p>
                  {{ getLabel('orderNo', order.billDetail.channel) }} : {{ order.billDetail.id }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="printPlate--receipt">
        <div class="printPlate--receipt_left">
          <div
            class="printPlate--receipt-store">
            <img
              class="printPlate--receipt-store-photo"
              alt="gw"
              src="https://s3-ap-southeast-1.amazonaws.com/storage-oms.akitahub/20200615111959uYgipGIKoe_49878">
            <p class="printPlate--receipt-store-name">
              {{ store.name || 'GW' }}
            </p>
            <p class="printPlate--receipt-store-address">
              {{ order.billDetail.storeAddress.address }} {{ order.billDetail.storeAddress.postcode }}
            </p>
          </div>
          <p class="printPlate--receipt-sender-title">
            {{ getLabel('customerAddress', order.billDetail.channel) }}
          </p>
          <div class="printPlate--receipt-customer-address">
            <div>
              <div>
                <p class="name">
                  {{ order.customer.name }}
                </p>
                <div class="address">
                  <p>
                    {{ order.customer.address }}
                    <span> {{ order.customer.subDistrict }} {{ order.customer.district }} {{ order.customer.province }} </span>
                    <span>{{ order.customer.postcode }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="printPlate--receipt-contact">
            <p>
              Tel. : {{ order.customer.tel }}
            </p>
          </div>
          <p class="printPlate--receipt-channel">
            {{ getLabel('channel', order.billDetail.channel) }}
          </p>
          <p> {{ store.name || 'GW' }} Store </p>
        </div>

        <div class="printPlate--receipt_right_large printPlate--custom__heigth1-1">
          <p class="printPlate--receipt-head">
            {{ getLabel('receipt', order.billDetail.channel) }}
          </p>
          <div class="printPlate--receipt-detail">
            <div class="printPlate--receipt-detail_left">
              <p class="printPlate--receipt-detail_head printPlate--title">
                {{ getLabel('orderNo', order.billDetail.channel) }}
              </p>
              <p class="printPlate--receipt-detail_content">
                {{ order.billDetail.id }}
              </p>
              <div>
                <p class="printPlate--receipt-detail_head printPlate--title">
                  {{ getLabel('orderDate', order.billDetail.channel) }}
                </p>
                <p class="printPlate--receipt-detail_content">
                  <span>{{ $dayjs(order.billDetail.createTime).locale('th').format('D/MM/YYYY HH:mm') }}</span>
                </p>
              </div>
              <p class="printPlate--receipt-detail_head printPlate--title">
                {{ getLabel('allItem', order.billDetail.channel) }}
              </p>
              <p class="printPlate--receipt-detail_content">
                {{ order.items.length }} {{ getLabel('item', order.billDetail.channel) }}
              </p>
            </div>
            <div class="printPlate--receipt-detail_right">
              <p class="printPlate--receipt-detail_head printPlate--title">
                {{ getLabel('status', order.billDetail.channel) }}
              </p>
              <p class="printPlate--receipt-detail_content">
                {{ getLabel('paid', order.billDetail.channel) }}
              </p>
            </div>
          </div>

          <div class="printPlate--receipt-thead printPlate--title">
            <div class="printPlate--receipt-thead_count">
              {{ getLabel('order', order.billDetail.channel) }}
            </div>
            <div class="printPlate--receipt-thead_col_list">
              {{ getLabel('listItem', order.billDetail.channel) }}
            </div>
            <div class="printPlate--receipt-thead_col_amount">
              {{ getLabel('amount', order.billDetail.channel) }}
            </div>
            <div class="printPlate--receipt-thead_col_price">
              {{ getLabel('price', order.billDetail.channel) }}
            </div>
          </div>

          <div
            v-for="(item,index) in order.items"
            :key="index"
            class="printPlate--receipt-tbody">
            <div class="printPlate--receipt-tbody_count">
              {{ index+1 }}
            </div>
            <div class="printPlate--receipt-tbody_col_list">
              <p>
                {{ item.name }} {{ concatOptions(item) }}
              </p>
              <p>
                {{ item.code }}
              </p>
            </div>
            <div class="printPlate--receipt-tbody_col_amount">
              <p>
                {{ item.amount }}
              </p>
            </div>
            <div class="printPlate--receipt-tbody_col_price">
              {{ item.price | showFullPriceFormat() }}
            </div>
            <div
              v-if="index+1 === order.items.length"
              class="page-break page-break_last">
            </div>
          </div>
        </div>
      </div>

      <div class="printPlate--receipt_right_bottom">
        <div class="printPlate--receipt-summary summary-background">
          <div class="printPlate--receipt-summary_left">
            <p class="printPlate--title">
              {{ getLabel('shippingFee', order.billDetail.channel) }}
            </p>
            <p class="printPlate--title">
              {{ getLabel('discount', order.billDetail.channel) }}
            </p>
            <p class="printPlate--title">
              {{ getLabel('net', order.billDetail.channel) }}
            </p>
          </div>
          <div class="printPlate--receipt-summary_right">
            <p>
              {{ order.billDetail.shippingPrice | showFullPriceFormat() }}
            </p>
            <p>
              {{ order.billDetail.discount | showFullPriceFormat() }}
            </p>
            <p class="printPlate--title">
              {{ order.billDetail.net | showFullPriceFormat() }}
            </p>
          </div>
        </div>
        <p class="printPlate--note">
          <span class="printPlate--title">{{ getLabel('note', order.billDetail.channel) }} :</span>
          {{ !!order.billDetail.note ? order.billDetail.note : '-' }}
        </p>
      </div>
      <div class="page-break" />
    </div>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode'
import OrderProvider from '@/resources/OrderProvider'
import { mapGetters } from 'vuex'
import { generateQRCode } from '@/assets/js/helper'

const OrderService = new OrderProvider()

export default {
  components: {
    barcode: VueBarcode
  },
  data () {
    return {
      items: [],
      orders: [],
      billDetail: {
        id: '',
        status: '',
        link: '',
        shippingPrice: '',
        shippingType: '',
        discount: '',
        net: '',
        Tracking: '',
        brand: '',
        note: '',
        createTime: ''
      },
      customer: {
        name: '',
        tel: '',
        address: '',
        subDistrict: '',
        district: '',
        province: '',
        postcode: '',
        country: ''
      },
      labels: {
        en: {
          sender: 'Sender',
          zipcode: 'Zipcode',
          receiver: 'Receiver',
          orderNo: 'Order No.',
          customerAddress: 'Customer Address',
          channel: 'Channel',
          receipt: 'Receipt',
          orderDate: 'Order Date',
          paid: 'Paid',
          status: 'Status',
          allItem: 'Amount item',
          subDistrict: '',
          district: '',
          item: '',
          order: '#',
          listItem: 'Items',
          amount: 'Amount',
          price: 'Price',
          shippingFee: 'Shipping fee',
          discount: 'Discount',
          net: 'Net',
          note: 'Note'
        },
        th: {
          sender: 'ผู้ส่ง',
          zipcode: 'รหัสไปรษณีย์',
          receiver: 'ผู้รับ',
          orderNo: 'บิลเลขที่',
          customerAddress: 'ชื่อ - ที่อยู่จัดส่งลูกค้า',
          channel: 'ช่องทางที่สั่งซื้อ',
          receipt: 'ใบเสร็จ / ใบส่งของ',
          orderDate: 'วันที่สั่งซื้อ',
          paid: 'จ่ายแล้ว',
          status: 'สถานะ',
          allItem: 'สินค้าทั้งหมด',
          subDistrict: 'ตำบล/แขวง',
          district: 'อำเภอ/เขต',
          item: 'รายการ',
          order: 'ลำดับ',
          listItem: 'รายการสินค้า',
          amount: 'จำนวน',
          price: 'ราคา',
          shippingFee: 'ค่าจัดส่ง',
          discount: 'ส่วนลด',
          net: 'ยอดรวมสุทธิ',
          note: 'หมายเหตุ'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      printList: 'Order/printList',
      store: 'Store/store'
    })
  },
  async mounted () {
    await this.getOrderDetail()
    setTimeout(() => window.print(), 1300)
  },
  methods: {
    getLabel (label, channel = 'web') {
      const lang = channel === 'online_international'
      || channel === 'online_singapore'
      || channel === 'chatbot_international'
      || channel === 'international_manual'
      ? 'en' : 'th'
      return this.labels[lang][label] || ''
    },
    storeAddress (channel) {
      const addressTh = {
        address: `คลังสินค้า ${this.store?.name || 'GW'} เลขที่ 1643/5 ถนนเพชรบุรี \nแขวงมักกะสัน เขตราชเทวี กรุงเทพมหานคร`,
        postcode: '10400'
      }

      const addressInter = {
        address: `${this.store?.name || 'GW'} Warehouse 1643/5 Phetchaburi Road, Makkasan, Ratchathewi, Bangkok, Thailand`,
        postcode: '10400'
      }

      return channel === 'online_international'
        || channel === 'online_singapore'
        || channel === 'chatbot_international'
        || channel === 'international_manual'
        ? addressInter : addressTh
    },
    async getOrderDetail () {
      this.loading = true
      try {
        const printList = JSON.parse(window.localStorage.getItem('print-list'))
        this.orders = await Promise.all(printList.map((each) => this.getEachOrders(each)))
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    async getEachOrders (orderId) {
      const { data } = await OrderService.getOrderWarehouseById(orderId)
      const { shippingAddress } = data
      const items = data.skus
      const billDetail = {
        id: data.orderId,
        status: data.currentState,
        link: `https://bill.akitahub.com/th/b/${data.url}`,
        shippingPrice: parseFloat(data.shipping.fee || 0),
        discount: parseFloat(data.discount || 0),
        net: parseFloat(data.net),
        Tracking: data.shipping.refCode,
        shippingType: data.shipping.method,
        note: data.note,
        createTime: data.createdAt,
        storeAddress: this.storeAddress(data.channel),
        channel: data.channel,
        qr: await generateQRCode(data.orderId)
      }
      const nameKeys = ['title', 'firstName', 'lastName']
      const addressKeys = ['address', 'address2']
      const customer = {
        name: this.mapFullName(nameKeys, shippingAddress),
        tel: shippingAddress.contactNo,
        address: this.mapFullName(addressKeys, shippingAddress),
        subDistrict: shippingAddress.subDistrict,
        district: shippingAddress.district,
        province: shippingAddress.stateProvince,
        postcode: shippingAddress.postcode,
        country: shippingAddress.country
      }

      return { billDetail, customer, items }
    },
    mapFullName (keys, object, joinChar = ' ') {
      const newArr = []
      keys.forEach((key) => {
        if (object[key]) {
          newArr.push(object[key])
        }
      })
      return newArr.join(joinChar).trim() || ''
    },
    concatOptions (item) {
      const keyConcat = ['color', 'size']
      const concat = this.mapFullName(keyConcat, item, ' - ')
      return concat ? `(${concat})` : '-'
    }
  }
}
</script>

<style>
#prinrLabel {
  color: #000000de;
  display: block;
  background: #ffffff;
  width: 230mm;
  min-height: 297mm;
  margin: 20px auto 20px;
  padding: 10mm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,.5);
}
#prinrLabel p {
  margin-bottom: 0;
}
div[page=A4] * {
  vertical-align: baseline;
}
@media print {
  @page {
    size: A4;
    margin: 10mm;
  }
  main.v-main {
    background-color: #fff !important;
  }
  nav.v-navigation-drawer {
    display: none;
  }
  header.v-sheet.v-toolbar.v-app-bar.v-app-bar--fixed {
    display: none;
  }
  main.v-main {
    padding-left: 0 !important;
  }
  #prinrLabel {
    width: 210mm;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  div.page {
    display: block;
  }
}
div.block, div.page {
  width: 100%;
  display: inline-block;
}
.page-break, .print-sm--signature, div.page {
  page-break-inside: avoid;
}
.printPlate--custom__heigth1 {
  height: 420px!important;
}
.printPlate--tag-box-large, .printPlate--tag-box-large-no {
  border-bottom: dashed 1px #ccc;
  height: 470px;
}
.printPlate--tag-box-large-no_inner-group-sender, .printPlate--tag-box-large_inner-group-sender {
  float: left;
  width: 304px;
  margin-right: 37px;
}
.printPlate--tag-box-large-no_inner-group, .printPlate--tag-box-large_inner-group {
  float: left;
  width: 452px;
}
.printPlate--receiver-box_extra-small,
.printPlate--receiver-box_large,
.printPlate--receiver-box_small,
.printPlate--sender-box_small,
.printPlate--sender-box_large,
.printPlate--blue-box {
  border: 2px solid #a7a7a7;
  border-radius: 4px;
}
.printPlate--sender-box_large {
  float: left;
  width: 100%;
  height: 227px;
  font-size: 18px;
  margin-bottom: 28px;
  margin-right: 37px;
}
.printPlate--sender-body_post {
  height: 30px;
}
.printPlate--sender-box_large .printPlate--sender-head {
  border-bottom: solid 1px #a7a7a7;
  width: 100%;
  height: 45px;
  padding-left: 10px;
  line-height: 45px;
}
.printPlate--sender-box_large .printPlate--sender-head .tel {
  float: right;
  margin-right: 8px;
}
.printPlate--sender-box_large .printPlate--sender-body {
  height: calc(100% - 45px);
}
.printPlate--sender-body {
  width: 100%;
  height: calc(100% - 28px);
  padding: 15px 10px 10px;
  line-height: 1.3em;
}
.printPlate--receiver-box_large {
  float: left;
  width: 100%;
  height: 300px;
  font-size: 23px;
}
.printPlate--receiver-box_large .printPlate--receiver-head_left {
  float: left;
  height: 58px;
  width: 104px;
  border-right: solid 1px #a7a7a7;
  padding-left: 10px;
}
.printPlate--receiver-box_large .printPlate--receiver-head_right {
  float: left;
  height: 58px;
  width: calc(100% - 104px);
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
}
.printPlate--receiver-box_large .printPlate--receiver-head {
  border-bottom: solid 1px #a7a7a7;
  width: 100%;
  height: 58px;
  line-height: 2.5em;
}
.printPlate--receiver-box_large .printPlate--receiver-body {
  width: 100%;
  height: calc(100% - 58px);
  padding: 20px;
}
.printPlate--receiver-box_large .printPlate--receiver-body_address {
  height: calc(100% - 40px);
  line-height: 1.2em;
}
.printPlate--receiver-box_large .printPlate--receiver-body_post {
  height: 40px;
}
.printPlate--receiver-box_large .printPlate--receiver-body_post_left {
  float: left;
  height: 40px;
  line-height: 2.8em;
  vertical-align: bottom;
}
.printPlate--receiver-box_large .printPlate--receiver-body_post_right {
  float: right;
  font-size: 44px;
}
.printPlate--bar-code-box {
  float: left;
  margin-top: 15px;
  margin-bottom: 10px;
}
.printPlate--bar-code-detail {
  float: left;
  height: 100%;
  font-size: 11px;
  line-height: 1.2em;
  padding-left: 5px;
}
.printPlate--receipt {
  margin-top: 5px;
  padding-top: 35px;
  font-size: 11px;
}
.printPlate--receipt_left {
  float: left;
  width: 50%;
  padding-right: 60px;
}
.printPlate--receipt-store {
  margin-bottom: 40px;
}
.printPlate--receipt-store-photo {
  width: 50px;
  height: 50px;
  border: 1px solid #D8D8D8;
  margin-bottom: 5px;
}
.printPlate--receipt-channel, .printPlate--receipt-sender-title, .printPlate--title {
  font-size: 12px;
  font-weight: 700;
}
.printPlate--receipt-customer-address {
  width: 185px;
}
.printPlate--receipt-channel, .printPlate--receipt-contact {
  margin-top: 15px;
}
.printPlate--receipt_right_large {
  float: left;
  width: 50%;
  min-height: 485px;
  padding-bottom: 20px;
}
.printPlate--custom__heigth1-1 {
  min-height: 540px;
}
.printPlate--receipt-head {
  text-align: right;
  font-size: 21px;
  font-weight: 700;
  padding-right: 10px;
}
.printPlate--receipt-detail {
  margin-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  line-height: 2em;
  width: 100%;
}
.printPlate--receipt-detail_left {
  float: left;
  width: 75%;
}
.printPlate--receipt-detail_head {
  float: left;
  width: 30%;
}
.printPlate--receipt-detail_content {
  float: left;
  width: 70%;
}
.printPlate--receipt-detail_right {
  float: left;
  width: 25%;
  text-align: right;
}
.printPlate--receipt-thead {
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  height: 20px;
  border-bottom: 1px solid #979797;
}
.printPlate--receipt-thead_count {
  width: 10%;
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}
.printPlate--receipt-thead_col_list {
  width: 50%;
  float: left;
  padding-left: 5px;
  padding-right: 5px;
}
.printPlate--receipt-thead_col_amount {
  width: 20%;
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}
.printPlate--receipt-thead_col_price {
  width: 20%;
  float: right;
  padding-left: 5px;
  padding-right: 0;
  text-align: right;
}
.printPlate--receipt-tbody {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  min-height: 32px;
  line-height: 1.2em;
  display: inline-block;
  width: 100%;
}
.printPlate--receipt-tbody_count {
  width: 10%;
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}
.printPlate--receipt-tbody_col_list {
  width: 50%;
  float: left;
  padding-left: 5px;
  padding-right: 5px;
}
.printPlate--receipt-tbody_col_amount {
  width: 20%;
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}
.printPlate--receipt-tbody_col_price {
  width: 20%;
  float: right;
  padding-left: 5px;
  padding-right: 0;
  text-align: right;
}
.page-break {
  page-break-after: always;
  display: block;
}
.printPlate--receipt_right_bottom {
  display: inline-block;
  margin-left: 50%;
  width: 50%;
  line-height: 2em;
  font-size: 11px;
}
.printPlate--receipt-summary {
  line-height: 1.8em;
  height: 75px;
  padding: 10px;
  background-color: #F5F5F5;
  margin-bottom: 5px;
}
.printPlate--note {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 0 10px;
  line-height: 14px;
}
.printPlate--receipt-summary_left {
  float: left;
  width: 75%;
}
.printPlate--receipt-summary_right {
  float: left;
  width: 25%;
  text-align: right;
}
.printPlate--sender-body_post {
  height: 30px;
}
.printPlate--sender-body_address {
  height: calc(100% - 30px);
  word-wrap: break-word;
  white-space: pre-line;
}
.printPlate--sender-body_post_left {
  float: left;
  height: 30px;
  line-height: 34px;
}
.printPlate--sender-body_post_right {
  float: right;
  font-size: 30px;
}
#prinrLabel svg.vue-barcode-element {
  user-select: none;
  display: block;
}
</style>
